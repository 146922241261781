<template>
  <a-card :bordered="false" class="card-area">
    <div>
      <div class="operator">
        <a-button
          v-hasPermission="['dept:add']"
          type="primary"
          ghost
          @click="clickAdd"
          >新增</a-button
        >
        <a-button v-hasPermission="['dept:delete']" @click="batchDelete"
          >删除</a-button
        >
        <a-button v-hasPermission="['dept:export']" @click="() => {}"
          >导出Excel</a-button
        >
      </div>

      <div class="body" style="display: flex">
        <div
          class="left-menu"
          style="width: 350px; border: 1px solid #eee; margin-right: 10px; background: #f9f9f9;">
          <a-tree
            checkable
            v-model="checkedPositionKeys"
            :selected-keys="selectedPositionKeys"
            :tree-data="deptTreeData"
          />
        </div>
        <!-- 表格区域 -->
        <a-table
          bordered
          ref="TableInfo"
          style="flex-grow: 1"
          :pagination="{
            pageSizeOptions: ['15', '20', '30', '50', '100'],
            defaultCurrent: 1,
            defaultPageSize: 20,
            showQuickJumper: true,
            showSizeChanger: true,
          }"
          :rowKey="(record) => record.deptPositionId"
          :columns="columns"
          :dataSource="deptPositionListDataFiltered"
          :loading="loading"
          :scroll="{ x: 900 }"
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
          <span v-if="record.prepareRoles" slot="prepareRoles" slot-scope="text, record">
            <a-popover placement="topLeft"
                v-for="(item, index) of record.prepareRoles.split(',')"
                :key="index"
            >
              <template slot="content">
                <div> {{getRoleById(item).remark}} </div>
              </template>
              <a-tag
                color="green"
              >
                {{ getRoleById(item).roleName }}
              </a-tag>
            </a-popover>
          </span>

          <template slot="operation" slot-scope="text, record">
            <a-icon
              v-hasPermission="['dept:update']"
              type="edit"
              theme="twoTone"
              twoToneColor="#4a9ff5"
              @click="clickEdit(record)"
              title="修改"
            ></a-icon>
            <a-badge
              v-hasNoPermission="['dept:update']"
              status="warning"
              text="无权限"
            ></a-badge>
          </template>
        </a-table>
      </div>
    </div>
    <!-- 新增部门 -->
    <!-- 修改部门 -->
    <dept-position-edit ref="deptPositionEdit" @success="handleDeptEditSuccess">
    </dept-position-edit>
  </a-card>
</template>

<script>
import RangeDate from "@/components/datetime/RangeDate";
import DeptPositionEdit from "./DeptPositionEdit";
import {getDeptRootId, getEatonRootIds} from './../../../utils/tools'

export default {
  name: "DeptPosition",
  components: { DeptPositionEdit, RangeDate },
  data() {
    return {
      deptTreeData: [],
      deptPositionListData: [],
      roleData: [],
      selectedRowKeys: [],
      sortedInfo: null,
      loading: false,
      deptAddVisiable: false,
      selectedPositionKeys: [],
      checkedPositionKeys: [],
    };
  },
  computed: {
    columns() {
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      return [
        {
          title: "职位",
          dataIndex: "deptPositionName",
          width: 150,
        },
        {
          title: "所属部门",
          dataIndex: "deptName",
          width: 150,
        },
        {
          title: "可用角色范围",
          dataIndex: "prepareRoles",
          scopedSlots: { customRender: "prepareRoles" },
        },
        {
          title: "排序",
          dataIndex: "orderNum",
          width: 65,
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          sorter: true,
          sortOrder: sortedInfo.columnKey === "createTime" && sortedInfo.order,
          customRender: (text) => {
            return this.datetime(text);
          },
          width: 150,
        },
        {
          title: "修改时间",
          dataIndex: "modifyTime",
          sorter: true,
          sortOrder: sortedInfo.columnKey === "modifyTime" && sortedInfo.order,
          customRender: (text) => {
            return this.datetime(text);
          },
          width: 150,
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
      ];
    },
    deptPositionListDataFiltered() {
      if (this.checkedPositionKeys && this.checkedPositionKeys.length > 0) {
        return this.deptPositionListData.filter((item) => {
          return this.checkedPositionKeys.indexOf(String(item.deptId)) >= 0;
        });
      }
      return this.deptPositionListData;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    fetch() {
      this.loading = true;
      let params = { pageSize: 100000, pageNum: 1 };

      this.$get("dept", { rootDeptIds: getEatonRootIds() }).then((r) => {
        let data = r.data;
        this.deptTreeData = data.rows.children;

        this.$get("dept-position", params).then((r) => {
          // 过滤掉产品经理 和 运维人员 的职位  huawengang
          this.deptPositionListData = r.data.rows.filter(item=>(item.deptPositionId!=7 && item.deptPositionId!=8));;

          this.$get("role/list").then((r) => {
            this.roleData = r.data.data;
            this.loading = false;
          });
        });
      });
    },
    getRoleById(id) {
      let role = this.roleData.find((item) => item.roleId == id) || {};
      return role;
    },
    clickAdd() {
      this.$refs.deptPositionEdit.setForm(false);
    },
    clickEdit(record) {
      this.$refs.deptPositionEdit.setForm(true, record);
    },
    batchDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
        return;
      }
      this.$confirm({
        title: "确定删除所选中的记录?",
        content: "当您点击确定按钮后，这些记录将会被彻底删除",
        centered: true,
        onOk: () => {
          let ids = this.selectedRowKeys.join(",");
          this.$delete("dept-position/" + ids).then(() => {
            this.$message.success("删除成功");
            this.selectedRowKeys = [];
            this.fetch();
          });
        },
        onCancel() {
          this.selectedRowKeys = [];
        },
      });
    },
    handleDeptEditSuccess() {
      this.fetch();
    },
  },
  watch: {
    checkedPositionKeys() {},
  },
};
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>
