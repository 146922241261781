var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"card-area",attrs:{"bordered":false}},[_c('div',[_c('div',{staticClass:"operator"},[_c('a-button',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:(['dept:add']),expression:"['dept:add']"}],attrs:{"type":"primary","ghost":""},on:{"click":_vm.clickAdd}},[_vm._v("新增")]),_c('a-button',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:(['dept:delete']),expression:"['dept:delete']"}],on:{"click":_vm.batchDelete}},[_vm._v("删除")]),_c('a-button',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:(['dept:export']),expression:"['dept:export']"}],on:{"click":function () {}}},[_vm._v("导出Excel")])],1),_c('div',{staticClass:"body",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"left-menu",staticStyle:{"width":"350px","border":"1px solid #eee","margin-right":"10px","background":"#f9f9f9"}},[_c('a-tree',{attrs:{"checkable":"","selected-keys":_vm.selectedPositionKeys,"tree-data":_vm.deptTreeData},model:{value:(_vm.checkedPositionKeys),callback:function ($$v) {_vm.checkedPositionKeys=$$v},expression:"checkedPositionKeys"}})],1),_c('a-table',{ref:"TableInfo",staticStyle:{"flex-grow":"1"},attrs:{"bordered":"","pagination":{
          pageSizeOptions: ['15', '20', '30', '50', '100'],
          defaultCurrent: 1,
          defaultPageSize: 20,
          showQuickJumper: true,
          showSizeChanger: true,
        },"rowKey":function (record) { return record.deptPositionId; },"columns":_vm.columns,"dataSource":_vm.deptPositionListDataFiltered,"loading":_vm.loading,"scroll":{ x: 900 },"rowSelection":{
          selectedRowKeys: _vm.selectedRowKeys,
          onChange: _vm.onSelectChange,
        }},scopedSlots:_vm._u([{key:"prepareRoles",fn:function(text, record){return (record.prepareRoles)?_c('span',{},_vm._l((record.prepareRoles.split(',')),function(item,index){return _c('a-popover',{key:index,attrs:{"placement":"topLeft"}},[_c('template',{slot:"content"},[_c('div',[_vm._v(" "+_vm._s(_vm.getRoleById(item).remark)+" ")])]),_c('a-tag',{attrs:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.getRoleById(item).roleName)+" ")])],2)}),1):_vm._e()}},{key:"operation",fn:function(text, record){return [_c('a-icon',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:(['dept:update']),expression:"['dept:update']"}],attrs:{"type":"edit","theme":"twoTone","twoToneColor":"#4a9ff5","title":"修改"},on:{"click":function($event){return _vm.clickEdit(record)}}}),_c('a-badge',{directives:[{name:"hasNoPermission",rawName:"v-hasNoPermission",value:(['dept:update']),expression:"['dept:update']"}],attrs:{"status":"warning","text":"无权限"}})]}}],null,true)})],1)]),_c('dept-position-edit',{ref:"deptPositionEdit",on:{"success":_vm.handleDeptEditSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }