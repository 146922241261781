<template>
  <a-drawer
    :title="`${viewModel ? '修改职位' : '新建职位'}`"
    :maskClosable="false"
    width="400"
    placement="right"
    :closable="true"
    @close="onClose"
    :visible="deptPositionEditVisiable"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <a-form-model
      ref="ruleForm"
      :validateOnRuleChange="true"
      :model="deptPositonForm"
      :rules="deptPositonRules"
      :form="deptPositonForm"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-model-item label="职位名称" prop="deptPositionName">
        <a-input
          placeholder="输入职位名称"
          v-model="deptPositonForm.deptPositionName"
          style="width: 80%"
        />
      </a-form-model-item>

      <a-form-model-item label="所属部门" prop="deptId">
        <a-tree-select
          v-model="deptPositonForm.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptTreeData"
          placeholder="选择部门"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>

      <a-form-model-item label="可用角色" prop="prepareRoles">
        <a-select
          mode="multiple"
          :value="deptPositonFormRoleIdSplited"
          placeholder="选择角色"
          @change="
            (arr) => {
              deptPositonForm.prepareRoles = arr.join(',');
            }
          "
        >
          <a-select-option
            v-for="(r, index) in roleData"
            :value="String(r.roleId)"
            :key="index"
            >{{ r.roleName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="排序" prop="orderNum">
        <a-input
          type="number"
          v-model="deptPositonForm.orderNum"
          style="width: 50%"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-bootom-button">
      <a-button @click="onClose" style="margin-right: 0.8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading"
        >提交</a-button
      >
    </div>
  </a-drawer>
</template>
<script>
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
import {getDeptRootId, getEatonRootIds} from './../../../utils/tools'

export default {
  name: "DeptPositionEdit",
  data() {
    return {
      loading: false,
      formItemLayout,
      deptTreeData: [],
      roleData: [],
      viewModel: null,
      deptPositionEditVisiable: false,
      deptPositonForm: {
        deptPositionId: undefined,
        deptId: undefined,
        deptPositionName: undefined,
        orderNum: undefined,
        prepareRoles: undefined,
      },
      deptPositonRules: {
        deptPositionName: [
          { required: true, message: "请填写职位名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "职位名称长度需在1到20之间",
            trigger: "blur",
          },
        ],
        deptId: [{ required: true, message: "请选择部门", trigger: "blur" }],
        prepareRoles: [
          { required: true, message: "请选择角色", trigger: "blur" },
        ],
      },
      backupData: null,
    };
  },
  mounted() {
    this.$get("dept", { rootDeptIds: getEatonRootIds() }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
    this.$get("role/list").then((r) => {
      // 过滤掉超级管理员  huawengang
      this.roleData = r.data.data.filter(item=>(item.roleId!=1));
    });
  },
  methods: {
    setForm(modelType, data) {
      this.viewModel = modelType;
      if (modelType) {
        for (let key in data) {
          this.deptPositonForm[key] = data[key];
        }
        this.backupData = JSON.parse(JSON.stringify(this.deptPositonForm));
      } else {
        this.backupData = {};
        for (let key in this.deptPositonForm) {
          this.deptPositonForm[key] = undefined;
          this.backupData[key] = undefined;
        }
      }
      this.deptPositionEditVisiable = true;
    },
    reset() {
      if (this.backupData) {
        for (let key in this.backupData) {
          this.deptPositonForm[key] = this.backupData[key];
        }
      }
    },
    onClose() {
      this.loading = false;
      this.deptPositionEditVisiable = false;
      this.reset();
      this.$emit("close");
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let deptPositon = this.deptPositonForm;

          if (this.viewModel) {
            this.loading = true;
            this.$put("dept-position", {
              ...deptPositon,
            })
              .then((r) => {
                this.loading = false;
                this.$message.success("修改职位成功");
                this.$emit("success");
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.loading = true;
            this.$post("dept-position", {
              ...deptPositon,
            })
              .then((r) => {
                this.loading = false;
                this.$message.success("新建职位成功");
                this.$emit("success");
                this.reset();
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
  computed: {
    deptPositonFormRoleIdSplited() {
      if (this.deptPositonForm && this.deptPositonForm.prepareRoles) {
        return this.deptPositonForm.prepareRoles.split(",");
      }
      return [];
    },
  },
  watch: {
    "deptPositonForm.prepareRoles"(){
console.log(this.deptPositonForm.prepareRoles)
    }
  },
};
</script>
